/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

Slide enter
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateX(5px);
  z-index: 1;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateX(0);
  transition: opacity 300ms linear 100ms, transform 300ms ease-in 100ms;
}

/* slide exit */
/* .slide-exit {
  opacity: 1;
  transform: scale(1) translateX(0);
}

.slide-exit.slide-exit-acitve {
  opacity: 0;
  transform: scale(0.97) translateX(5px);
  transition: opacity 150ms linear 100ms, transform 150ms ease-out;
}

.slide-exit-done {
  opacity: 0;
} */
/* a {
  text-decoration: underline !important;
} */

.pre-loader {
  position: fixed;
  top: 30%;
  left: 55%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

@media only screen and (max-width: 900px) {
  .go-to-button {
    display: none;
  }
}

/* Keeping the footer where it belongs */

.over-all-container {
  position: relative;
  min-height: 100vh;
}

/* NECESSARY FOR FOOTER TO WORK */
.content-wrapper {
  padding-bottom: 350px;
}

footer {
  position: absolute;
  bottom: 0;
  min-height: 200px;
  width: 100%;
  padding: 20px 10px;
  background-color: #3f51b5;
  margin-top: 50px;
}

footer .col-6 {
  margin-top: 10px;
}

footer a {
  color: #ffffff;
  text-decoration: none;
  line-height: 25px;
}

body {
  height: 100%;
}

/* The Header */
#header {
  padding: 50px 10px;
}

#header .header-text {
  text-align: center;
  font-size: 80px;
  padding: 20px;
}

.doctors-image {
  width: 100%;
}

#header .header-text-one {
  font-size: 40px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

#header .header-text-two {
  font-size: 20px !important;
}

@media only screen and (max-width: 900px) {
  #header .header-text {
    padding: 30px 50px;
  }
}

@media only screen and (max-width: 700px) {
  #header .header-text {
    padding: 20px 20px;
  }
}

@media only screen and (min-width: 1500px) {
  #header .header-text {
    padding: 20px 70px;
  }
}

@media only screen and (min-width: 1200px) {
  #header .header-text-one {
    font-size: 55px !important;
  }
}

/* Homepage blocks */
#blocks {
  width: 90%;
  margin: 20px auto;
  text-align: center;
}

.block-a {
  margin: 0 auto !important;
}

.block-b {
  margin: 0 auto !important;
}

/* Home Features images */
.feature-image img {
  max-height: 100%;
  max-width: 100%;
}

.feature-texts {
  text-align: center;
  margin: 40px auto !important;
  padding: 40px 80px !important;
}

.inner-feature-text-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.feature-texts Button {
  margin-top: 20px;
  padding: 5px 60px !important;
}

.images-header {
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}

.images-details {
  margin-top: 20px !important;
  color: #495057;
  /* width: 80%; */
  text-align: center;
  padding: 0;
}

.hide-on-pc {
  display: none;
}

@media only screen and (max-width: 900px) {
  .hide-on-pc {
    display: block;
  }
  .hide-on-phone {
    display: none;
  }
  .feature-texts {
    margin: 5px auto !important;
    padding: 20px 20px !important;
  }

  .images-details {
    width: 95%;
  }
}

/* Spaced Repetition */
.spaced-rep-text {
  width: 40%;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .spaced-rep-text {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  .spaced-rep-text {
    width: 80%;
  }
}

#spaced-repetition {
  background-color: #1a2561;
  color: #ffffff;
  padding: 40px 20px;
  margin: 80px auto;
}

#spaced-repetition .repetition-header {
  text-align: center;
}

#spaced-repetition .repetition-main-text {
  text-align: center;
  margin: 10px auto;
}

#spaced-repetition .repetition-types {
  text-align: center;
  margin-top: 50px !important;
  margin: 10px auto;
}

#spaced-repetition .repetition-types h3 {
  margin-top: 30px;
}

#spaced-repetition .repetition-types p {
  text-align: center;
  margin: 10px auto;
  max-width: 500px;
}

/* Upcoming Features */
#upcoming-features {
  margin-top: 50px;
  width: 90%;
  padding-left: 45px;
}

.upcom-feature-icon {
  border: 1px solid white;
  border-radius: 40%;
  padding: 15px;
  font-size: 60px !important;
  background-color: #dfc7df;
}

.upcom-feature {
  margin-bottom: 30px !important;
  word-wrap: break-word;
}

.feature-icon {
  margin-right: 20px;
}

@media only screen and (max-width: 900px) {
  #upcoming-features {
    margin-top: 50px;
    width: 95% !important;
    padding-left: 20px;
    padding-right: 5px;
    margin-right: 0px;
  }

  .upcom-feature-icon {
    border-radius: 40%;
    padding: 12px;
    font-size: 50px !important;
  }

  .upcom-feature-texts h6 {
    font-size: 20px !important;
  }
}

/* Action Area */
#action-area {
  margin-top: 100px;
  margin-bottom: 80px;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;
  background-color: #505782;
  color: white;
}

#action-area Button {
  margin-top: 20px;
  padding: 14px 40px;
  font-weight: bolder;
  font-size: 20px;
}

#action-area .action-area-text {
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  #action-area .action-area-text {
    width: 90%;
  }
}

/* ROBOT PAGE */
#robot-container {
  width: 30%;
  margin: 0px auto;
}

#robot-container #chat-container {
  margin: 75px auto;
}

#robot-container #show-add-section {
  position: fixed;
  top: 90px;
  right: 5%;
  color: blue;
  border: 1px solid blue;
  background-color: rgba(255, 0, 0, 0.577);
}

#robot-container #user-input {
  width: 100%;
}

#robot-container #submit-clerk {
  position: fixed;
  top: 55px;
  right: 5%;
  color: blue;
  border: 1px solid blue;
  background-color: rgba(255, 0, 0, 0.577);
}

#robot-container #chat-container .chat-chunk {
  margin-bottom: 50px !important;
}

#robot-container #chat-container .current-sec {
  display: block;
}

#robot-container .user-paragraph {
  position: relative;
  float: left;
  border: 1px solid blue;
  border-radius: 0px 15px 15px 15px;
  padding: 5px;
  background-color: rgba(0, 0, 255, 0.406);
  font-weight: bolder;
}

#robot-container .robot-paragraph {
  position: relative;
  float: right;
  border: 1px solid green;
  border-radius: 15px 0px 15px 15px;
  padding: 5px;
  margin-bottom: 50px !important;
  background-color: rgba(0, 128, 0, 0.406);
  font-weight: bolder;
}

@media only screen and (max-width: 900px) {
  #robot-container {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  #robot-container {
    width: 80%;
  }
}

/* CHAT OVERVIEW */
#chat-overview {
  width: 30%;
  margin: 20px auto;
}

#chat-overview .user-paragraph {
  color: blue;
}

#chat-overview .robot-paragraph {
  color: green;
  padding-left: 20px;
}

#chat-overview .section-name {
  text-transform: uppercase;
  margin-top: 30px;
}

@media only screen and (max-width: 900px) {
  #chat-overview {
    width: 60%;
  }
}
@media only screen and (max-width: 650px) {
  #chat-overview {
    width: 80%;
  }
}

/* OSCE PAGE */
.MuiAccordionDetails-root {
  display: block !important;
}

.accordion .accordion-summary p {
  margin: 0px;
}

.osce-practice-checkboxes {
  margin: 0px !important;
}
.osce-practice-checkboxes p {
  margin: 0px;
}

/* ABOUT PAGE */
#about-container .feature-image {
  margin: auto;
}

#about-container .images-details {
  color: #495057 !important;
  width: 60%;
  margin: auto;
  text-align: justify;
  text-indent: 30px;
}

@media only screen and (max-width: 900px) {
  #about-container .images-details {
    width: 75%;
  }
}
@media only screen and (max-width: 650px) {
  #chat-overview {
    width: 90%;
  }
}

/* CONTACT PAGE */
#contact-us-container .feature-image {
  margin: 50px auto;
}

/* QUIZ PREVIEW PAGE */
.practice-options {
  position: relative;
}

.practice-options input {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}

.quiz-options p {
  margin: 0px;
}

/* FLASHCARDS PAGE */
#flashcards-action-buttons .button-grids {
  padding: 10px 5px;
  text-align: center;
}

#flashcards-action-buttons .button-grids Button {
  font-size: 15px;
}

@media only screen and (max-width: 900px) {
  #flashcards-action-buttons .button-grids Button {
    font-size: 10px;
  }
}

.MuiPagination-ul {
  justify-content: center;
}

.MuiPaginationItem-page {
  height: 40 !important;
  padding: 0 10px !important;
  font-size: 0.9375rem !important;
}

@media only screen and (max-width: 900px) {
  .MuiPaginationItem-page {
    height: 26px !important;
    padding: 0 4px !important;
    margin: 0 1px !important;
    min-width: 26px !important;
  }
}

/* ANALYTICS PAGE */
.analytics-paragraphs p {
  margin-bottom: 0;
  display: inline;
}
